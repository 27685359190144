<template>
    <a-modal :visible="true" :width="$mq !== 'lg' ? 800 : 1300" @cancel="$emit('close')" :footer="null">
        <div class="dF fC f1 mt-3" ref="scroller" @scroll="scrollingContainerShadow" >
			<a-card class="pt-2 md__px-2 f1 dF fC" style="max-width:1600px;width:100%" :bodyStyle="{display:'flex',flexDirection:'column', flex:1}">
				<a-steps :current="current" class="mb-5">
					<a-step :title="$mq === 'lg' ? 'Step 1' : null" :description="$mq === 'lg' ? 'Provide transaction information' : null" />
					<a-step :title="$mq === 'lg' ? 'Step 2' : null" :description="$mq === 'lg' ? 'Provide Participant information' : null" />
					<a-step :title="$mq === 'lg' ? 'Step 3': null" :description="$mq === 'lg' ? 'Product Details' :null" />
					<a-step :title="$mq === 'lg' ? 'Step 4' : null" :description="$mq === 'lg' ? 'Finalize Document' : null" />
					<a-step v-if="!isManual" :title="$mq === 'lg' ? 'Step 5' : null" :description="$mq === 'lg' ? isManual ? 'Print & Sign' : 'Set Up Signing Order' : null"  />
				</a-steps>

				<div class="f1">
					<transition name="slide-fadein-right" mode="out-in">
						<component :setManual="setManual" :updateTransaction="true" :transaction="currentTransaction" :is="`Step${current + 1}`" :key="current" @setFn="setValidatingFn" @done="next" v-model="currentTransaction" @close="$emit('close')" />
					</transition>
				</div>

				<div>
					<a-divider />
					<div class="dF jSB mt-3">
						<a-button size="large" class="mr-5" type="secondary" @click="prev">BACK</a-button>
						<a-button :disabled="!isManual && !integrated.active" size="large" type="primary" @click="currFn">{{nextLookup[current+1] ? nextLookup[current+1] : 'NEXT'}}</a-button>
					</div>
				</div>
			</a-card>
    	</div>
    </a-modal>
</template>

<script>
import {scrollingContainerShadow} from 'bh-mod'
import Step1 from '@/components/steps/Step1'
import Step2 from '@/components/steps/Step2'
import Step3 from '@/components/steps/AddOnStep3'
import Step4 from '@/components/steps/AddOnStep4'
import Step5 from '@/components/steps/AddOnStep5'

export default {
    props:['openModal', 'transaction'],
    components:{scrollingContainerShadow, Step1,Step2,Step3,Step4,Step5},
    data(){
        return {
            isManual:false,
			currValidate:() => console.log(null),
			currentTransaction:{
				type:'',
				template:'',
				purchasers:[],
				product:{},
				contract:{},
				offer:{},
				signingParty:[],
				other:{},
				opportunityAt:null,
				contactAt:null,
				opportunity:null,
				contact:null,
				query:{},
			},
			rep:{},
			current:0,
			currentStepObj:{},
        }
    },
	computed:{
		instance(){
			return this.$store.state.instance
		},
		nextLookup(){
			return {
				4:this.isManual ? 'NEXT: REVIEW & DOWNLOAD' : 'NEXT: SIGNING ORDER',
				5:'NEXT: REVIEW & SEND'
			}
		},
		integrated(){
			return this.$store.state.appData.vendors.docusign
		},
    },
    watch:{
		current(val){
			this.$refs.scroller.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	},
	methods:{
		setManual(is){
			this.isManual = is
		},
		scrollingContainerShadow,
		setValidatingFn(validateFn){
			this.currValidate = validateFn
		},
		currFn(){
			return this.currValidate()
		},
		updatePurchasers(array){
			array.forEach(applicant => {

				let pid = Date.now();

				let purchaser = {
					id:pid,
					firstName:applicant.firstName,
					middleName:applicant.middleName,
					lastName:applicant.lastName,
					email:applicant.email,
					phone:applicant.phone,
					company:applicant.company,
					business:'',
					cell:'',
					dob:'',
					issuing:'',
					issuingCountry:'',
					expiry:'',
					idType:'pp',
					idValue:'',
					passportOtherValue: applicant.passportOtherValue || '',
					passportOtherIssuing: applicant.passportOtherIssuing || '',
					passportOtherIssuingCountry: applicant.passportOtherIssuingCountry || '',
					passportOtherExpiry: applicant.passportOtherExpiry || '',
					address1:applicant.address || '',
					address2:'',
					postal:applicant.postal || '',
					country:applicant.country || '',
					region:applicant.region || '',
					city:applicant.city || '',
					jobTitle:applicant.jobTitle || '',
					ssnsin: applicant.ssnsin || '',
				}
				this.currentTransaction.purchasers.push(purchaser)

			})
		},
		prev(){
			if (this.current === 0){
				let self = this
				return this.$confirm({
					title: `Are you sure you want to exit updating currentTransaction?`,
					okText:'Exit',
					okType: 'danger',
					centered: true,
					onOk() {
						self.$emit('close');
					},
					onCancel() {
					},
				})

			} else {

				this.current--

			}
		},
		next(e){
			if (this.current == 1){
				let err = false
				e.purchasers.forEach( x => {
					if (!(x.firstName.trim() && x.lastName.trim() && x.email.trim() && x.phone.trim() && (x.idValue || x.passportOtherValue) && x.address1 && x.city && x.region && x.country)){
						err = true
					}
				})
				if (err) return this.$message.error('Some of purchasers information are missing. Please fill out the required information.')

				$.extend(true, this.currentTransaction, e)
				this.current++
			} else if (this.current == 2){
				this.currentTransaction.product = e.product
				this.currentTransaction.offer = e.offer

				const pkg = e.product.unit && e.product.unit.package;
				if(pkg && pkg.other && pkg.other.addons){
					const newParking = pkg.other.addons.parking && pkg.other.addons.parking.map(p => {return {...p, preDefined: true}}) || [];
					const newLockers = pkg.other.addons.lockers && pkg.other.addons.lockers.map(l => {return {...l, preDefined: true}}) || [];
					const newBikeRacks = pkg.other.addons.bikeRacks && pkg.other.addons.bikeRacks.map(b => {return {...b, preDefined: true}}) || [];

					pkg.other.addons = {
						parking:[...newParking],
						lockers:[...newLockers],
						bikeRacks:[...newBikeRacks]
					}

					this.transaction.product.unit.package.other.addons = pkg.other.addons
					this.transaction.product.unit.packages[0].other.addons = pkg.other.addons
				}
				this.current++
			} else if (this.current == 3){
				let {package:pkg} = e
				if (typeof pkg === 'object'){
					this.currentTransaction.product.unit.package = JSON.parse(JSON.stringify(pkg))
					this.currentTransaction.product.unit.packages[0] = JSON.parse(JSON.stringify(pkg))
				}
				$.extend(true, this.currentTransaction.offer, e.offer)
				$.extend(true, this.currentTransaction.other, e.other)

				this.current++
			} else {
				$.extend(true, this.currentTransaction, e)
				this.current++
			}
		},

	},

	async created(){
		$.extend(true, this.currentTransaction, this.transaction)
	}
}
</script>
