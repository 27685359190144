<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow:scroll"
		ref="scroller" @scroll="scrollingContainerShadow">
		<BHLoading :show="loadingPage" />
		<transactionPDF :download="download" @completed="download = false" />
		<template v-if="currentTransaction && currentTransaction.id">
			<div class="mb-3 dF aC jSB">
				<router-link to="/" class="trans-title" style="cursor: pointer; display: inline-block;">
					<div class="dF jSB aC w-full">
						<div>
							<a-icon class="mr-3" style="font-size: 1.75rem;" type="arrow-left" />
							<h3 style="display: inline-block;">
								Back to all Transactions</h3>
							<br />
							<h5 class="ml-5" style="display: inline-block;">{{ transactionName }} </h5>
						</div>
					</div>
				</router-link>
				<div class="dF aC" style="gap: 20px">
					<div v-if="currentTab === 'docs'">
						<a-button @click="downloadAllDocs" icon="download" size="large" type="primary" ghost>
							Download All Documents
						</a-button>
					</div>
					<div v-if="editingRights">
						<a-button @click="editTransaction" type="link" style="font-size: 20px; padding: 0px" icon="edit"
							size="small">
							Edit
						</a-button>
					</div>
					<div class="bg-danger text-white rounded dF aC" v-if="currentTransaction.status === 'void'">VOIDED
					</div>
					<div class="dF aC cursor-pointer" v-else @click="fetchTransactionDetails">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">Refresh</template>
							<a-icon type="sync" style="font-size: 20px; color: var(--primary);" />
						</a-tooltip>
					</div>
					<div>
						<a-button @click="download = true;" type="link" style="font-size: 20px; padding: 0px"
							icon="download" size="small">
							PDF
						</a-button>
					</div>
				</div>
			</div>

			<a-tabs class="transactionTabs hide-scrollbar" v-model="currentTab" style="overflow-y:scroll">
				<a-tab-pane key="details" tab="Details">
					<detailsTab />
				</a-tab-pane>
				<a-tab-pane key="purchasers" :tab="currentTransaction.type === 'lease' ? 'Tenants & Reps' : 'Purchasers & Reps'">
					<purchasersTab />
				</a-tab-pane>
				<a-tab-pane key="docs" tab="Documents">
					<documentsTab />
				</a-tab-pane>
				<a-tab-pane key="participants" tab="Internal Participants">
					<participantsTab />
				</a-tab-pane>
				<a-tab-pane key="notes" tab="Notes">
					<notesTab />
				</a-tab-pane>
				<a-tab-pane key="timeline" tab="Timeline">
					<timelineTab />
				</a-tab-pane>
			</a-tabs>
		</template>
	</div>
</template>

<script>

import { scrollingContainerShadow } from 'bh-mod'
import BHLoading from 'bh-mod/components/common/Loading'
import detailsTab from '@/components/transactiontabs/detailsTab'
import documentsTab from '@/components/transactiontabs/documentsTab'
import notesTab from '@/components/transactiontabs/notesTab'
import participantsTab from '@/components/transactiontabs/participantsTab'
import timelineTab from '@/components/transactiontabs/timelineTab'
import purchasersTab from '@/components/transactiontabs/purchasersTab'
import transactionPDF from '@/components/transaction/transactionPDF'
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from 'axios';

export default {
	components: {
		BHLoading, detailsTab, documentsTab, notesTab, participantsTab, timelineTab, purchasersTab, transactionPDF
	},
	data() {
		return {
			currentTab: 'docs',
			download: false,
			zip: new JSZip()
		}
	},
	computed: {
		transactionName() {
			if (this.instance.productType == 'highrise') {
				let { product = {}, purchasers = [], type } = this.currentTransaction
				let { floor = {} } = product
				let name = `${floor.name}`

				if (purchasers.length === 1) name += ` - (${type === 'purchase' ? 'Buyer' : 'Tenant'}: ${purchasers[0].fullName})`
				else name += ` - ${purchasers.length} ${type === 'purchase' ? 'Purchasers' : 'Tenants'}`

				return name
			} else {
				let { product = {}, purchasers = [], type } = this.currentTransaction
				let { lot = {} } = product
				let name = `Lot ${lot.name}${lot.block ? ` (Block ${lot.block})` : ''}`
				if (lot.address) {
					name += ` - ${lot.address}`
				} else {
					if (purchasers.length === 1) name += ` - (${type === 'purchase' ? 'Buyer' : 'Tenant'}: ${purchasers[0].fullName})`
					else name += ` - ${purchasers.length} ${type === 'purchase' ? 'Purchasers' : 'Tenants'}`

				}
				return name
			}
		},
		currentTransaction() {
			return this.$store.state.appData.currentTransaction
		},
		instance() {
			return this.$store.state.instance
		},
		loadingPage: {
			get() {
				return this.$store.state.appData.loadingPage
			},
			set(val) {
				this.$store.commit('LOAD_PAGE', val)
			}
		},
		currentEnvelopes() {
			return this.$store.getters.currentEnvelopes
		},
		mainEnvelop() {
			return this.currentEnvelopes.find(e => e.main)
		},
		user() {
			return this.$store.state.user.user
		},
		editingRights() {
			let canEdit = false
			if (['created', 'pending'].includes(this.currentTransaction.status) && this.currentTransaction.owners && this.currentTransaction.owners.length) {
				this.currentTransaction.owners.forEach(x => {
					if (x.id == this.user.id && this.mainEnvelop && this.mainEnvelop.signedSoFar === 0) {
						canEdit = true
					}
				})
			}
			return canEdit;
		}
	},
	methods: {
		scrollingContainerShadow,

		fetchTransactionDetails() {
			this.$store.commit('LOAD')
			let param = this.$route.params.id
			this.$api.get(`/transactions/:instance/${param}`).then(({ data }) => {
				if (data.envelopes && data.envelopes.length) {
					data.envelopes = data.envelopes.filter(x => !x.deleted)
				}
				this.$store.commit('SET_PROP', { where: ['transactions', data.id], what: data })
				this.$store.commit('SET_CURRENT_TRANSACTION', data)

				if (this.firstTimeManual) {
					this.$message.info('This is a manual transaction. Please download and print the main envelope')
				}
				this.$store.commit('LOAD', false)
			}).catch((err) => {
				this.$store.commit('LOAD', false)
				this.$router.push('/')
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `Transaction not found`))
				}
			})
		},

		editTransaction() {
			this.$router.push(`/transaction/edit/${this.currentTransaction.id}`)
		},

		async downloadAllDocs() {
			this.$store.commit('LOAD', true)
			try {
				const envelops = this.currentTransaction.envelopes;

				const fileList = [];
				envelops.forEach(e => {
					let { file } = e;
					file.forEach(f => {
						fileList.push({ name: e.name, ...f})
					})
				})

				let fileURLs = [];

				fileList.forEach((file) => {
					fileURLs.push(this.$api.get(`/contract-templates/:instance/getfile?key=${file.key}`).then((({ data }) => data)))
				})

				const urlList = await Promise.all(fileURLs);

				let downloadPromises = [];

				urlList.forEach((url) => {
					//using default axios as wrapper is through cross error
					downloadPromises.push(axios.get(url, { responseType: 'blob' }).then(({ data }) => data))
				})

				const result = await Promise.all(downloadPromises);

				const nameCount = {};
				result.forEach((file, index) => {
					let name = fileList[index].name;

					// If the name already exists in the object, append a counter to make it unique
					if (nameCount[name] !== undefined) {
						nameCount[name] += 1;
						const extensionIndex = name.lastIndexOf('.');
						if (extensionIndex === -1) {
							name = `${name} (${nameCount[name]})`;
						} else {
							name = `${name.slice(0, extensionIndex)} (${nameCount[name]})${name.slice(extensionIndex)}`;
						}
					} else {
						nameCount[name] = 0;
					}

					// Add the file to the zip with the unique name
					this.zip.file(name, file);
				})
				let fileName = `transaction-documents-${this.currentTransaction.id}.zip`
				this.zip.generateAsync({ type: "blob" }).then(function (b) {
					saveAs(b, fileName);
				});
				this.zip = new JSZip()
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(self.$err(err, 'Something happened while trying to download all documents. Please try again.'))
				}
			}
			this.$store.commit('LOAD', false)
		}
	},
	created() {
		this.currentTab = this.$route.query.tab || 'docs'
		this.$store.commit('SET_CURRENT_ELEVATION', '');
	},
	mounted() {
		if (location.href.includes('?v=001')) {
			this.firstTimeManual = true
			this.$router.replace('/transaction/' + this.$route.params.id)
		}
		this.fetchTransactionDetails();
	}
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
.contacts-page {
	@media screen and (min-width:567px) {
		margin-top: 5%;
	}
}

.aB {
	align-items: baseline;
}

.trans-title h3 {
	color: black;
	transition: color 0.2s ease-in-out;
}

.trans-title:hover h3 {
	color: var(--orange) !important;
	transition: color 0.2s ease-in-out;
}
</style>

<style lang="scss">
.transactionTabs .ant-tabs-nav {
	font-size: 18px;

	.ant-tabs-tab {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.transactionTabs .ant-tabs-bar {
	margin: 0 0 25px 0;
}
</style>
