<template>
    <div class="w-full timelinePage">
        <div class="mt-3" style="padding: 50px;">
            <div v-for="(date,dateI) in createDates" :key="date+dateI">
                <h5 class="mb-4">{{textDate(date.date)}}</h5>
                <a-timeline>
                    <div class="dF" v-for="(d,i) in date.sorted" :key="d+i">
                        <div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
                            {{textTime(d)}}
                        </div>
                        <a-timeline-item>{{d.logNote}}</a-timeline-item>
                    </div>
                </a-timeline>
            </div>
        </div>
    </div>
</template>

<script>

import BHLoading from 'bh-mod/components/common/Loading'

const getLog = (obj, cUser) => {
    let itemLookup = {
        transactions:'transaction',

    }
    
    let {data,user,createdAt,type} = obj 

    let currentUser = cUser
    let userName = ''
    if (currentUser && currentUser.id && user && user.id){
        userName = currentUser.id === user.id ? 'You' : `${user.firstName} ${user.lastName[0]}.`
    }
    let item = type
    let {action,override} = data
    let verb = 'interacted with'

    console.log('VERB', verb)

    if (action){
        verb = action 
    }
    
    console.log('VERB', verb)
    return {
        date:createdAt,
        logNote:`${userName} ${action}`,
    }

}

export default {
    components:{
        BHLoading,
    },
    data() {
        return {
            loading: false,
        }
    },
    watch:{
        
    },
    computed:{
        events(){
            return this.$store.state.appData.currentTransaction.timelines.map(e => getLog(e,this.$store.state.user.user ))
        },
        createDates(){
            let dates = {}
            this.events.forEach( x => {
                // console.log('X FROM EVENTS', new Date(x.date))
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                // console.log('HAS OWN PROPERTY', `${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}`)
                if (!dates.hasOwnProperty(`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    let obj = {
                        date:`${month}` + '/' + `${day}` + `/` + `${year}`,
                        sorted:this.sortDates(`${month}` + '/' + `${day}` + `/` + `${year}`)
                    }
                    dates[obj.date] = obj
                }
            })
            // console.log('ALL DATES', dates)
            let sorted = Object.values(dates).sort( (a,b) => {
                let {date:date1} = a 
                let {date:date2} = b
                
                date1 = new Date(date1).getTime()
                date2 = new Date(date2).getTime()

                return date1 > date2 ? -1 : 1
            })
            console.log('SORTED DATESSSS', sorted)
            
            return sorted
        },
        filter(){
            return this.$store.state.appData.filter
        },
        transactions(){
            return Object.values(this.$store.state.appData.transactions)
        },
        filteredTransactions(){
            return this.$store.getters.filteredTransactions
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        },
    },
    methods:{
        textTime(date){
            let d = new Date(date.date)
            let minutes = d.getMinutes().toString()
            if (minutes.length < 2){
                minutes = '0' + minutes
            }
            return d.getHours() + ':' + minutes
        },
        textDate(date){
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },
        sortDates(date){
            let dates = []
            this.events.forEach( x => {
                let xDate = new Date(x.date)
                let month = xDate.getMonth() + 1
                let day = xDate.getDate()
                let year = xDate.getFullYear()
                if (date == (`${month}` + '/' + `${day}` + `/` + `${year}`)){
                    dates.push(x)
                }
            })
            dates.sort((a, b) => {
                return new Date(b.date) - new Date(a.date)
            })
            return dates
        }
    },
    created(){
        
    },
    mounted(){

    }
}
</script>

<style lang="scss" scoped>
    .timelinePage{
        background-color: white;
        border: 1px solid #E4E7EB;
    }
</style>

<style lang="scss">
    .ant-timeline-item-last .ant-timeline-item .ant-timeline-item-tail {
        display: none;
    }
</style>