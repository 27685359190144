<template>
    <div class="w-full">
        <noteModal />

        <a-card :bodyStyle="{padding:0}">
            <div>
                <template v-if="notes && notes.length">
                    <div class="dF highlight-row p-4 aS" style="width: 100%; gap: 2.5em;" v-for="(note,noteI) in notes" :key="note+noteI" >
                        <div>
                            <div class="dF aC">
                                <svg width="24" height="24" viewBox="0 0 20 20" fill="#9ea0a5"><path id="Icon_material-comment" data-name="Icon material-comment" d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z" transform="translate(-3 -3)"/> </svg>
                                <div class="ml-4">
                                    <div style="">{{note.subject}}</div>
                                    <div style="color: #9EA0A5;">{{formatDate(note.updatedAt)}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="f1">
                            {{note.content}}
                        </div>
                        <div>
                            <div class="dF" style="justify-content:flex-end;" v-if="editingRights">
                                <div class="mr-4 edit-delete" @click="editNote(note)">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </div>
                                <div class="edit-delete" @click="deleteNote(note)">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                </div>
                            </div>
                        </div>
                        <!-- <a-col :span="0" v-if="false">
                            <div class="dF" style="justify-content:flex-end;">
                                <div v-if="note.createdBy.avatar" class="profile-icon">
                                    <div class="inside" :style="{ 'background-image': 'url(' + `${note.createdBy.avatar}` + ')' }" />
                                </div>
                                <div v-else class="profile-icon">
                                    <a-icon type="user" style="font-size: 30px; margin-top: 5px; color: #DDDCDF;" />
                                </div>
                            </div>
                        </a-col> -->
                    </div>
                    <a-row v-if="editingRights" style="height: 55px;">
                        <a-col :span="24" style="border-top: 1px solid #E4E7EB; height: 55px; padding: 16px;">
                            <div @click="$store.commit('SHOW_NOTE_MODAL',{task:{},type:'add',visible:true})" class="dF" style="color: var(--orange); cursor: pointer; width: 130px;">
                                <a-icon type="plus-circle" class="mr-3" style="color: var(--orange); font-size: 22px;" />
                                <div style="height: 22px; line-height: 22px; text-decoration: underline;">Add new note</div>
                            </div>
                        </a-col>
                    </a-row>
                </template>

                <template v-else>
                    <a-row style="height: 100px;">
                        <a-col :span="24" style="text-align: center; line-height: 100px; height: 100px;">You have no notes</a-col>
                    </a-row>
                    <a-row v-if="editingRights" style="height: 55px;">
                        <a-col :span="24" style="border-top: 1px solid #E4E7EB; height: 55px; padding: 16px;">
                            <div @click="$store.commit('SHOW_NOTE_MODAL',{task:{},type:'add',visible:true})" class="dF" style="color: var(--orange); cursor: pointer; width: 130px;">
                                <a-icon type="plus-circle" class="mr-3" style="color: var(--orange); font-size: 22px;" />
                                <div style="height: 22px; line-height: 22px; text-decoration: underline;">Add new note</div>
                            </div>
                        </a-col>
                    </a-row>
                </template>
            </div>
        </a-card>
    </div>
</template>

<script>

import BHLoading from 'bh-mod/components/common/Loading'
import noteModal from '@/components/common/noteModal'
import {formatDate} from 'bh-mod'

export default {
    components:{
        BHLoading,noteModal
    },
    data() {
        return {
            loading: false,
        }
    },
    watch:{

    },
    computed:{
        notes(){
            let notes = []
            if (this.transaction.notes && Object.keys(this.transaction.notes).length) notes = this.transaction.notes
            return Object.values(notes).map((x,xI) => {
                if (!x.updatedAt) x.updatedAt = Date.now() - 2000 * xI
                return x
            }).sort( (a,b) => b.updatedAt - a.updatedAt)
        },
        editingRights(){
            let canEdit = false
            if (this.$p >= 40){
                return true
            } else {
                this.transaction.owners.forEach( x => {
                    if (x.id == this.user.id){
                        canEdit = true
                    }
                })
                return canEdit
            }
        },
        user(){
            return this.$store.state.user.user
        },
        transaction(){
            return this.$store.state.appData.currentTransaction
        },
        filter(){
            return this.$store.state.appData.filter
        },
        transactions(){
            return Object.values(this.$store.state.appData.transactions)
        },
        filteredTransactions(){
            return this.$store.getters.filteredTransactions
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        currentNotes(){
            let result = this.transaction.notes || []
            return result
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        }
    },
    methods:{
        formatDate,
        editNote(note){
            console.log('EDIT THIS NOTE', note)
            this.$store.commit('SHOW_NOTE_MODAL', {note: note.id, type: 'edit', visible: true})
        },
        deleteNote(note){
            let self = this
            console.log('DELETE THIS NOTE', note)
            this.$confirm({
                title: "Delete Note",
                content: h => <div>Do you want to delete this Note?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    console.log('DELETED NOTE')
                    let currentNotes = self.currentNotes
                    let notes = currentNotes.map(x => x.id === note.id ? {...note,updatedAt:Date.now()} : x)
                    let index = notes.findIndex( x => x.id == note.id)
                    notes.splice(index, 1)
                    console.log('NOTES AFTER DELETE', notes)
                    self.$api.put(`/transactions/:instance/${self.transaction.id}`, {notes}).then( ({data}) => {
                        self.$store.commit('SET_PROP', {where:['currentTransaction','notes'], what:notes})
                    }).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style lang="scss" scoped>
    .highlight-row:hover{
        background-color: var(--off-white-dark);
        cursor: pointer;
        transition: ease-in-out 0.3s;
    }
    .notesTable{
        background-color: white;
        border: 1px solid #E4E7EB;
        width: 100%;
    }
    .edit-delete {
		cursor: pointer;
		color: rgba(0, 0, 0, 0.65);
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
</style>

<style lang="scss">
    .profile-icon {
        border-radius: 40px;
        width: 40px;
        height: 40px;
        background-color: #F4F3F6;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .inside {
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50%;
        }
    }

</style>
