<template>
    <div>
        <h5>Product Details</h5>
        <a-divider />
        <a-form-model
            ref="step3"
            :model="step3"
            v-if="instance.productType === 'highrise'"
        >
            <div v-if="transaction.type === 'purchase'">
                <a-card title="Add Ons" class="mt-3" v-if="addOnsList.length">
                    <a-row :gutter="24">
                        <a-col
                            :span="$mq === 'lg' ? 8 : 24"
                            v-for="(addOn, index) in addOnsList"
                            :key="index"
                        >
							<a-row :gutter="16" v-if="$mq === 'lg'">
								<a-col :span="24" v-if="!noAddOnsInCC[addOn.type] && addOnsToShow[addOn.type] ">
									<h6>{{addOn.name}}</h6>
									<a-divider />
								</a-col>
							</a-row>
                            <div
                                v-for="addOnIndex in [0, 1, 2]"
                                :key="addOnIndex"
                            >
                                <a-row
                                    :gutter="16"
                                    v-if="addOnsToShow[addOn.type] > addOnIndex"
                                    :class="
                                        (index || addOnIndex) &&
                                        $mq !== 'lg'
                                            ? 'mt-3'
                                            : ''
                                    "
                                >
                                    <a-col :span="11">
                                        <p>{{ addOn.name }} #</p>
                                        <div
                                            v-if="
                                                !!addOns[addOn.type][addOnIndex]
                                                    .preDefined
                                            "
                                        >
                                            <a-input
                                                :value="
                                                    addOn.type +
                                                    ' #' +
                                                    addOns[addOn.type][
                                                        addOnIndex
                                                    ].name
                                                "
                                                :disabled="
                                                    !!addOns[addOn.type][
                                                        addOnIndex
                                                    ].preDefined
                                                "
                                            />
                                        </div>
                                        <div v-else>
                                            <a-select
                                                :value="
                                                    addOns[addOn.type][
                                                        addOnIndex
                                                    ].id
                                                "
                                                @change="
                                                    (e) =>
                                                        onAddOnChange(
                                                            e,
                                                            addOnIndex,
                                                            addOn.type
                                                        )
                                                "
                                                allowClear
                                            >
                                                <a-select-option
                                                    v-for="(
                                                        a, index
                                                    ) in generalAddOns[
                                                        addOn.type
                                                    ]"
                                                    :key="a.id + index"
                                                    :value="a.id"
                                                    :disabled="!!a.disabled"
                                                    >{{ addOn.name }} #{{
                                                        a.name
                                                    }}</a-select-option
                                                >
                                            </a-select>
                                        </div>
                                    </a-col>
                                    <a-col :span="1" />
                                    <a-col :span="12">
                                        <p>{{ addOn.name }} Price($)</p>
                                        <a-input-number
                                            @change="calculateTotalAddOnsPrice"
                                            style="
                                                width: 300px;
                                                max-width: 100%;
                                            "
                                            :formatter="
                                                (value) =>
                                                    '$ ' + formatPrice(value)
                                            "
                                            :parser="
                                                (value) =>
                                                    value.replace(
                                                        /\$\s?|(,*)/g,
                                                        ''
                                                    )
                                            "
                                            v-model="
                                                addOns[addOn.type][addOnIndex]
                                                    .price
                                            "
                                        />
                                    </a-col>
                                </a-row>
                                <div
                                    v-if="
                                        addOnsToShow[addOn.type] >
                                            addOnIndex + 1 && $mq === 'lg'
                                    "
                                >
                                    <a-divider />
                                </div>
                            </div>
                            <div v-if="index < 2 && $mq !== 'lg'">
                                <a-divider />
                            </div>
                        </a-col>
                    </a-row>

                    <a-divider class="mt-5" />

                    <a-row :gutter="16" class="mt-3">
                        <a-col :span="$mq === 'lg' ? 3 : 10">
                            <h6 class="mt-2">Add Ons MSRP</h6>
                        </a-col>
                        <a-col :span="$mq === 'lg' ? 4 : 10">
                            <a-input-number
                                style="width: 300px; max-width: 100%"
                                v-model="step3.addOnsPrice"
                                :formatter="
                                    (value) => '$ ' + formatPrice(value)
                                "
                                :parser="
                                    (value) => value.replace(/\$\s?|(,*)/g, '')
                                "
                                required
                            />
                        </a-col>
                    </a-row>
                </a-card>
            </div>

			<a-card title="Add Ons" class="mt-3" v-if="transaction.type === 'lease' && addOnsList.length">
				<a-row :gutter="24">
					<a-col
						:span="$mq === 'lg' ? 8 : 24"
						v-for="(addOn, index) in addOnsList"
						:key="index"
					>
						<a-row :gutter="16" v-if="$mq === 'lg'">
							<a-col :span="24" v-if="!noAddOnsInCC[addOn.type] && addOnsToShow[addOn.type] ">
								<h6>{{addOn.name}}</h6>
								<a-divider />
							</a-col>
						</a-row>
						<div
							v-for="addOnIndex in [0, 1, 2]"
							:key="addOnIndex"
						>
							<a-row
								:gutter="16"
								v-if="addOnsToShow[addOn.type] > addOnIndex"
								:class="
									(index || addOnIndex) &&
									$mq !== 'lg'
										? 'mt-3'
										: ''
								"
							>
								<a-col :span="11">
									<p>{{ addOn.name }} #</p>
									<div
										v-if="
											!!addOns[addOn.type][addOnIndex]
												.preDefined
										"
									>
										<a-input
											:value="
												addOn.type +
												' #' +
												addOns[addOn.type][
													addOnIndex
												].name
											"
											:disabled="
												!!addOns[addOn.type][
													addOnIndex
												].preDefined
											"
										/>
									</div>
									<div v-else>
										<a-select
											:value="
												addOns[addOn.type][
													addOnIndex
												].id
											"
											@change="
												(e) =>
													onAddOnChange(
														e,
														addOnIndex,
														addOn.type
													)
											"
											allowClear
										>
											<a-select-option
												v-for="(
													a, index
												) in generalAddOns[
													addOn.type
												]"
												:key="a.id + index"
												:value="a.id"
												:disabled="!!a.disabled"
												>{{ addOn.name }} #{{
													a.name
												}}</a-select-option
											>
										</a-select>
									</div>
								</a-col>
								<a-col :span="1" />
								<a-col :span="12">
									<p>{{ addOn.name }} Price($)</p>
									<a-input-number
										@change="calculateTotalAddOnsPrice"
										style="
											width: 300px;
											max-width: 100%;
										"
										:formatter="
											(value) =>
												'$ ' + formatPrice(value)
										"
										:parser="
											(value) =>
												value.replace(
													/\$\s?|(,*)/g,
													''
												)
										"
										v-model="
											addOns[addOn.type][addOnIndex]
												.rent
										"
									/>
								</a-col>
							</a-row>
							<div
								v-if="
									addOnsToShow[addOn.type] >
										addOnIndex + 1 && $mq === 'lg'
								"
							>
								<a-divider />
							</div>
						</div>
						<div v-if="index < 2 && $mq !== 'lg'">
							<a-divider />
						</div>
					</a-col>
				</a-row>

				<a-divider class="mt-5" />

				<a-row :gutter="16" class="mt-3">
					<a-col :span="$mq === 'lg' ? 3 : 10">
						<h6 class="mt-2">Add Ons MSRP</h6>
					</a-col>
					<a-col :span="$mq === 'lg' ? 4 : 10">
						<a-input-number
							style="width: 300px; max-width: 100%"
							v-model="step3.addOnsRent"
							:formatter="
								(value) => '$ ' + formatPrice(value)
							"
							:parser="
								(value) => value.replace(/\$\s?|(,*)/g, '')
							"
							required
						/>
					</a-col>
				</a-row>
			</a-card>

            <div v-if="transaction.type === 'lease'" class="mt-5 mx-3">
                <a-form-model-item
                    label="Tenancy Start Date"
                    prop="tenancyStartDate"
                    :rules="req('Please input the Tenancy Start Date')"
                >
                    <a-date-picker
                        style="width: 300px; max-width: 100%"
                        v-model="step3.tenancyStartDate"
                    />
                </a-form-model-item>

                <a-form-model-item
                    label="Tenancy End Date"
                    prop="tenancyStartDate"
                    :rules="req('Please input the Tenancy Start Date')"
                >
                    <a-date-picker
                        style="width: 300px; max-width: 100%"
                        v-model="step3.tenancyEndDate"
                    />
                </a-form-model-item>
            </div>

            <template v-if="transaction.type == 'purchase'">
                <a-row :gutter="16" class="mt-5 mx-3">
                    <a-col :span="12">
                        <a-form-model-item
                            label="Closing Date Type (Tarion)"
                            prop="firmClosing"
                            v-if="tarion"
                        >
                            <a-select
                                v-model="step3.closingType"
                                style="width: 400px; max-width: 100%"
                            >
                                <a-select-option value="tentative"
                                    >Tentative</a-select-option
                                >
                                <a-select-option value="firm"
                                    >Firm</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            label="Irrevocable Date"
                            prop="irrevocableDate"
                            :rules="req('Please input the Irrevocable Date')"
                        >
							<div class="dF aC" style="gap: 25px">
								<a-input v-if="notApplicable" v-model="step3.irrevocableDate" style="width: 40%;" disabled/>
								<a-date-picker v-else style="width: 400px; max-width: 100%" v-model="step3.irrevocableDate" :disabled-date="disabledDate" />
								<a-checkbox v-model="notApplicable" @change="onNotApplicableChange"> Not Applicable </a-checkbox>
							</div>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item
                            :label="closingDateLabel"
                            prop="closingDate"
                            :rules="req('Please input the Occupancy Date')"
                        >
                            <a-date-picker
                                v-model="step3.closingDate"
                                style="width: 400px; max-width: 100%"
                                :disabled-date="disabledDate"
                                @change="checkClosing"
                            />
                            <p v-if="closingDateError" class="text-danger">
                                Occupancy date cannot fall on a Holiday or a
                                Weekend. Please review and try again
                            </p>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="tarion"
                            :label="
                                tarion
                                    ? 'Outside Occupancy Date (Tarion)'
                                    : 'Outside Occupancy Date'
                            "
                            prop="outsideClosing"
                            :rules="req('Please input the Occupancy Date')"
                        >
                            <a-date-picker
                                style="width: 400px; max-width: 100%"
                                v-model="step3.outsideClosing"
                                :disabled-date="disabledDate"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </template>
        </a-form-model>
    </div>
</template>

<script>
import { isWorkingDay } from "bh-mod/helper";
import moment from "moment";

export default {
    props: ["transaction", "updateTransaction"],
    data: () => ({
		noAddOnsInCC: {
			parking: true,
			lockers: true,
			bikeRacks: true,
		},
        addOnsToShow: {
            parking: 0,
            lockers: 0,
            bikeRacks: 0,
        },
        addOns: {
            parking: [
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
            ],
            lockers: [
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
            ],
            bikeRacks: [
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
                { id: "", price: 0, rent: 0 },
            ],
        },
        closingDateError: false,
        step3: {
            closingType: "tentative",
            irrevocableDate: "",
            closingDate: "",
            outsideClosing: "",
            addOnsPrice: 0,
            addOnsRent:0,
            tenancyStartDate: null,
            tenancyEndDate: null,
        },
		notApplicable: false
    }),
    computed: {
		addOnsList() {
			return [
				{type:'parking', name: 'Parking Spot'},
				{type:'lockers', name: 'Locker'},
				{type:'bikeRacks', name: 'Bike Rack'},
			].filter(addOn => !this.noAddOnsInCC[addOn.type] && this.addOnsToShow[addOn.type])
		},
        closingDateLabel() {
            if (this.step3.closingType == "tentative") {
                return "Occupancy Date (1st Tentative)";
            } else {
                return "Occupancy Date (Firm)";
            }
        },

        condoAppSettings() {
            return (
                this.$store.state.appData.condoSettings &&
                this.$store.state.appData.condoSettings.options
            );
        },

        generalAddOns() {
            const addOns =
                (this.condoAppSettings && this.condoAppSettings.addons) || {};
            if (addOns.parking && addOns.parking.length) {
                addOns.parking = addOns.parking.filter(
                    (p) => !p.unitId && p.status !== "sold"
                );
            }

            if (addOns.lockers && addOns.lockers.length) {
                addOns.lockers = addOns.lockers.filter(
                    (l) => !l.unitId && l.status !== "sold"
                );
            }

            if (addOns.bikeRacks && addOns.bikeRacks.length) {
                addOns.bikeRacks = addOns.bikeRacks.filter(
                    (b) => !b.unitId && b.status !== "sold"
                );
            }
            return {
                parking: [...(addOns.parking || [])],
                lockers: [...(addOns.lockers || [])],
                bikeRacks: [...(addOns.bikeRacks || [])],
            };
        },

        unitAddOns() {
            const addOns =
                (this.transaction.product.unit &&
                    this.transaction.product.unit.package &&
                    this.transaction.product.unit.package.other &&
                    this.transaction.product.unit.package.other.addons) ||
                {};
            return {
                parking: [...(addOns.parking || [])],
                lockers: [...(addOns.lockers || [])],
                bikeRacks: [...(addOns.bikeRacks || [])],
            };
        },

        floorUnits() {
            if (!this.step3.floor.trim()) return [];
            let floor = this.condoFloors.find((x) => x.id === this.step3.floor);
            if (!floor) return false;
            return floor.units;
        },
        tarion() {
            let { country = "", region = "" } = this.instance;
            if (!country) country = "";
            if (!region) region = "";
            if (!country) return false;
            if (!region) return false;
            country = country.toLowerCase().trim();
            region = region.toLowerCase().trim();
            if (
                country === "canada" &&
                (region === "ontario" || region === "on")
            )
                return true;
            return false;
        },

        instance() {
            return this.$store.state.instance;
        },

        pkgPrice() {
            return this.pkg.price || 0;
        },

        pkg() {
            return (
                this.transaction.product.unit &&
                this.transaction.product.unit.package
            );
        },

        unitPremiums() {
            let unitPremiums =
                (this.transaction.product.unit &&
                    this.transaction.product.unit.package.other &&
                    this.transaction.product.unit.package.other.premiums) ||
                {};
            let price = 0;

            Object.values(unitPremiums).forEach((prm) => {
                if (!prm.active) return;
                if (prm.value) prm.value = +prm.value;
                if (isNaN(prm.value)) return;
                price += prm.value;
            });
            return price;
        },

        currentContract() {
            if (!this.transaction.contract) return {};
            return this.$store.state.appData.templates[this.transaction.template] || {};
        },
    },
    methods: {
        calculateTotalAddOnsPrice() {
			if (this.transaction.type == 'purchase'){
				this.step3.addOnsPrice = this.totalAddOnsPrice();
            } else {
				this.step3.addOnsRent = this.totalAddOnsRent();
            }
        },

        totalAddOnsPrice() {
            let price = 0;
            let Ids = []
			this.addOnsList.forEach((addOn) => {
				this.addOns[addOn.type].forEach(a => {
					if(a.id && a.price && !Ids.includes(a.id)){
						Ids.push(a.id)
						price +=a.price
					}
				})
			})
			return price
        },

		totalAddOnsRent() {
            let rent = 0;
            let Ids = []
			this.addOnsList.forEach((addOn) => {
				this.addOns[addOn.type].forEach(a => {
					if(a.id && a.rent && !Ids.includes(a.id)){
						Ids.push(a.id)
						rent +=a.rent
					}
				})
			})
			return rent
        },

        req: (msg) => ({ required: true, message: msg }),

        checkClosing(date) {
            if (!date || !date.unix) return;
            let time = date.unix() * 1000;
            if (!isWorkingDay(time)) this.closingDateError = true;
            else this.closingDateError = false;
        },
        isWorkingDay,
        disabledDate(current) {
            return current && current < moment().startOf("day");
        },

        formatPrice: (p) => {
            if (typeof p === "string") p = parseFloat(parseFloat(p).toFixed(2));
            if (!p) return `0`;
            return p.toLocaleString("en-us");
        },

        validate() {
            let self = this;

            this.$refs.step3.validate(async (valid) => {
                if (valid) {
                    this.step;
                    this.$store.commit("LOAD", true);
                    let sendObj = {};
                    let timestamp = new Date(this.step3.closingDate).getTime();
                    let isValidClosing = isWorkingDay(timestamp);

                    if (!isValidClosing){
						this.$store.commit("LOAD", false);
						return (this.closingDateError = true);
					}
                    else this.closingDateError = false;
                    let { tenancyStartDate, tenancyEndDate } = this.step3;

                    if (tenancyEndDate && tenancyEndDate.format)
                        tenancyEndDate = tenancyEndDate.format("YYYY-MM-DD");
                    if (tenancyStartDate && tenancyStartDate.format)
                        tenancyStartDate =
                            tenancyStartDate.format("YYYY-MM-DD");

                    const finalAddOns = {};

                    this.addOnsList.forEach((addOn) => {
                        finalAddOns[addOn.type] = [];
                        finalAddOns[addOn.type] = this.addOns[addOn.type]
                            .filter((a) => a.id && a.price)
                            .map((a) => {
                                return {
                                    ...a,
                                    status: "hold",
                                    unitId: this.transaction.product.unit.id,
                                };
                            });
                    });

                    this.transaction.product.unit.package.other.addons =
                        finalAddOns;
                    this.transaction.product.unit.packages[0].other.addons =
                        finalAddOns;

                    sendObj.product = this.transaction.product;

                    if (this.transaction.type == "purchase") {
                        sendObj.offer = {
                            ...this.transaction.offer,
                            addOnsPrice: this.step3.addOnsPrice,
                            addOnsRent: this.step3.addOnsRent,
                            closingType: this.step3.closingType,
                            irrevocableDate: (this.notApplicable || !this.step3.irrevocableDate) ? 'N/A' : moment(
                                this.step3.irrevocableDate
                            ).format("MMM-DD-YYYY"),
                            closingDate: moment(this.step3.closingDate).format(
                                "MMM-DD-YYYY"
                            ),
                            outsideClosing: moment(
                                this.step3.outsideClosing
                            ).format("MMM-DD-YYYY"),
                        };
                    } else {
                        sendObj.offer = {
                            ...this.transaction.offer,
                            addOnsPrice: this.step3.addOnsPrice,
                            addOnsRent: this.step3.addOnsRent,
                            totalRent: this.step3.totalRent,
                            deposit: this.step3.deposit,
                            tenancyStartDate,
                            tenancyEndDate,
                        };
                    }

                    this.$store.commit("LOAD", false);
                    self.$emit("done", sendObj);
                }
            });
        },

        onAddOnChange(id, index, type = "parking") {
            if (id) {
                const foundAddOn = this.generalAddOns[type].find(
                    (addOn) => addOn.id === id
                );
                if (foundAddOn) {
                    this.addOns[type][index] = {...foundAddOn, rent: foundAddOn.price};
                }
            } else {
                this.addOns[type][index] = {
                    id: "",
                    price: 0,
					rent:0
                };
            }

            this.$forceUpdate();
            this.calculateTotalAddOnsPrice();
        },

		onNotApplicableChange(event){
			if(event.target && event.target.checked){
				this.step3.irrevocableDate = 'N/A'
			} else {
				this.step3.irrevocableDate = ''
			}
		},
    },
    created() {
        if (this.updateTransaction) {
            this.step3.closingType = this.transaction.offer.closingType;
			if(this.transaction.offer.irrevocableDate === 'N/A'){
				this.notApplicable = true;
				this.step3.irrevocableDate = 'N/A'
			} else {
				this.step3.irrevocableDate = this.transaction.offer.irrevocableDate && moment(this.transaction.offer.irrevocableDate) || null
			}
            this.step3.closingDate =
                this.transaction.offer.closingDate &&
                moment(this.transaction.offer.closingDate);
            this.step3.outsideClosing =
                this.transaction.offer.outsideClosing &&
                moment(this.transaction.offer.outsideClosing);
            this.step3.tenancyStartDate =
                (this.transaction.offer.tenancyStartDate &&
                    moment(this.transaction.offer.tenancyStartDate)) ||
                null;
            this.step3.tenancyEndDate =
                (this.transaction.offer.tenancyEndDate &&
                    moment(this.transaction.offer.tenancyEndDate)) ||
                null;
        }
    },
    mounted() {
        this.$emit("setFn", this.validate);
        this.$api.get("/settings/:instance/condounits").then(({ data }) => {
            if (data) {
                this.$store.commit("SET_CONDO_SETTINGS", data);
            }
        }).catch((err) => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
        });

        if (this.unitAddOns) {
            this.unitAddOns.parking.forEach((p,index) => {
				this.addOns.parking[index] = {...p, rent: p.price};
			})
			this.unitAddOns.lockers.forEach((l,index) => {
				this.addOns.lockers[index] = {...l, rent: l.price};
			})
			this.unitAddOns.bikeRacks.forEach((b,index) => {
				this.addOns.bikeRacks[index] = {...b, rent: b.price};
			})
            this.addOnsToShow.parking = this.unitAddOns.parking.length;
            this.addOnsToShow.lockers = this.unitAddOns.lockers.length;
            this.addOnsToShow.bikeRacks = this.unitAddOns.bikeRacks.length;
        }

        let { pages = [] } = this.currentContract;

        pages.forEach((page) => {
            let { fields = [] } = page;
            fields.forEach((spot) => {
                let { field } = spot;

                if (
                    field.name.startsWith("#1 Parking Spot") &&
                    this.addOnsToShow.parking <= 1
                ) {
                    this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 1;
                }
                if (
                    field.name.startsWith("#2 Parking Spot") &&
                    this.addOnsToShow.parking <= 2
                ) {
                    this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 2;
                }
                if (
                    field.name.startsWith("#3 Parking Spot") &&
                    this.addOnsToShow.parking <= 3
                ) {
                    this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 3;
                }

                if (
                    field.name.startsWith("#1 Locker") &&
                    this.unitAddOns.lockers.length <= 1
                ) {
                    this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 1;
                }
                if (
                    field.name.startsWith("#2 Locker") &&
                    this.unitAddOns.lockers.length <= 2
                ) {
                    this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 2;
                }
                if (
                    field.name.startsWith("#3 Locker") &&
                    this.unitAddOns.lockers.length <= 3
                ) {
                    this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 3;
                }

                if (
                    field.name.startsWith("#1 Bike Rack") &&
                    this.unitAddOns.bikeRacks.length <= 1
                ) {
                    this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 1;
                }
                if (
                    field.name.startsWith("#2 Bike Rack") &&
                    this.unitAddOns.bikeRacks.length <= 2
                ) {
                    this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 2;
                }
                if (
                    field.name.startsWith("#3 Bike Rack") &&
                    this.unitAddOns.bikeRacks.length <= 3
                ) {
                    this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 3;
                }
            });
        });

        this.addOnsToShow.parking = Math.min(
            this.addOnsToShow.parking,
            this.pkg.maxParking || 0
        );
        this.addOnsToShow.lockers = Math.min(
            this.addOnsToShow.lockers,
            this.pkg.maxLocker || 0
        );
        this.addOnsToShow.bikeRacks = Math.min(
            this.addOnsToShow.bikeRacks,
            this.pkg.maxBikeRack || 0
        );

        setTimeout(() => {
            this.calculateTotalAddOnsPrice();
        }, 1000);
    },
};
</script>

<style>
</style>
