<template>
    <a-card>
        <ScanLicense :options="scanOptions" :whoName="whoName"  @close="closeScan" type="dl" />
        <a-modal :visible="participantModal.show" :title="participantModal.title" class="modal-large" @ok="createParticipant" @cancel="resetModal" :okText="participantModal.edit ? 'UPDATE' : 'ADD'" cancelText="CANCEL" centered>
            <a-form-model :model="purchaser" ref="purchaser" v-if="participantModal.type ==='purchaser'">
				<a-alert message="Use purchaser information as it appears on Government ID." type="warning" show-icon/>
				<hr />
                <a-row :gutter="48">
                    <a-col :span="12">
                        <a-form-model-item label="First Name"  prop="firstName" :rules="req(`First Name is required`)">
                            <a-input type="text" v-model="purchaser.firstName" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Last Name"  prop="lastName" :rules="req(`Last Name is required`)">
                            <a-input type="text" v-model="purchaser.lastName" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :span="12">
                        <a-form-model-item label="Middle Name"  prop="middleName">
                            <a-input type="text" v-model="purchaser.middleName" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Date of Birth"  prop="dob" :rules="req(`Date of birth is required`)">
							<a-date-picker placeholder="eg. 2022-12-31" v-model="purchaser.dob" :disabled-date="disabledDate" style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :span="12">
                        <a-form-model-item label="Email"  prop="email" :rules="{validator:isEmail, required:true, message:'Email is required'}">
                            <a-input type="text" v-model="purchaser.email" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Phone#"  prop="phone" :rules="req(`Phone is required`)">
                            <a-input type="text" v-model="purchaser.phone" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="Business Phone#"  prop="business" >
                            <a-input type="text" v-model="purchaser.business" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Cell Phone#"  prop="cell">
                            <a-input type="text" v-model="purchaser.cell" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="Company"  prop="company">
                            <a-input type="text" v-model="purchaser.company" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="Occupation"  prop="occupation">
                            <a-input type="text" v-model="purchaser.occupation" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Job Title"  prop="jobTitle">
                            <a-input type="text" v-model="purchaser.jobTitle" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="SSN/SIN #"  prop="ssnsin">
                            <a-input type="text" v-model="purchaser.ssnsin" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="Driver License #"  prop="idValue" :rules="purchaser.passportOtherValue ? [] : req(`Driver License # is required`)">
                            <a-input type="text" v-model="purchaser.idValue">
                                <a-icon @click="addPurchaserModal('editScanLicense')" slot="suffix" type="camera" />
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="License Issuing Jurisdiction"  prop="issuing">
                            <a-input type="text" v-model="purchaser.issuing" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="License Issuing Jurisdiction - Country"  prop="issuingCountry">
                            <a-input type="text" v-model="purchaser.issuingCountry" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="License Expiry"  prop="expiry">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.expiry" style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="Identification Type"  prop="idType" >
                            <a-select v-model="purchaser.idType">
								<a-select-option v-for="type in identificationTypes" :key="type.value" :value="type.value">{{ type.label }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
						<a-form-model-item :label="passportOther.valueLabel[purchaser.idType]" prop="passportOtherValue"
							:rules="purchaser.idValue ? [] : req(`${passportOther.valueLabel[purchaser.idType]} is required`)">
							<a-input type="text" v-model="purchaser.passportOtherValue">
								<a-icon v-if="purchaser.idType === 'pp'" @click="addPurchaserModal('scanPassport')"
									slot="suffix" type="camera" />
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item :label="passportOther.issuingLabel[purchaser.idType]" prop="passportOtherIssuing">
							<a-input type="text" v-model="purchaser.passportOtherIssuing" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item
							:label="passportOther.issuingCountryLabel[purchaser.idType]" prop="passportOtherIssuingCountry">
							<a-input type="text" v-model="purchaser.passportOtherIssuingCountry" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item :label="passportOther.expiryLabel[purchaser.idType]" prop="passportOtherExpiry">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.passportOtherExpiry"
								style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
                        <a-form-model-item label="Address 1"  prop="address1" :rules="req(`Address is required`)">
                            <a-input type="text" v-model="purchaser.address1" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Address 2"  prop="address2" >
                            <a-input type="text" v-model="purchaser.address2" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="City"  prop="city" :rules="req(`City is required`)">
                            <a-input type="text" v-model="purchaser.city" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="State/Province"  prop="region" :rules="req(`State/Province is required`)" >
                            <a-input type="text" v-model="purchaser.region" />
                        </a-form-model-item>
                    </a-col>
					<a-col :md="12">
                        <a-form-model-item label="Zip/Postal Code"  prop="postal" :rules="req(`Zip/Postal is required`)">
                            <a-input type="text" v-model="purchaser.postal" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Country"  prop="country" :rules="req(`Country is required`)">
                            <a-input type="text" v-model="purchaser.country" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
            </a-form-model>
            <a-form-model :model="rep" ref="rep" v-else>
                <a-row :gutter="32">
                    <a-col :span="12">
                        <a-form-model-item label="First Name"  prop="firstName" :rules="req(`First Name is required`)">
                            <a-input type="text" v-model="rep.firstName" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Last Name"  prop="lastName" :rules="req(`Last Name is required`)">
                            <a-input type="text" v-model="rep.lastName" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :span="12">
                        <a-form-model-item label="Email"  prop="email" :rules="{validator:isEmail, required:true, message:'Email is required'}">
                            <a-input type="text" v-model="rep.email" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Phone#" prop="phone">
                            <a-input type="text" v-model="rep.phone" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
                        <a-form-model-item label="Cell Phone#" prop="cell">
                            <a-input type="text" v-model="rep.cell" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Company Name"  prop="company">
                            <a-input type="text" v-model="rep.company" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="Job Title"  prop="jobTitle">
                            <a-input type="text" v-model="rep.jobTitle" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Company Address 1"  prop="address1" :rules="req(`Address is required`)">
                            <a-input type="text" v-model="rep.address1" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="Company Address 2"  prop="address2" >
                            <a-input type="text" v-model="rep.address2" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="City"  prop="city" :rules="req(`City is required`)">
                            <a-input type="text" v-model="rep.city" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="State/Province"  prop="region" :rules="req(`State/Province is required`)" >
                            <a-input type="text" v-model="rep.region" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12">
                        <a-form-model-item label="Zip/Postal Code"  prop="postal" :rules="req(`Zip/Postal is required`)">
                            <a-input type="text" v-model="rep.postal" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="32">
                    <a-col :md="12">
                        <a-form-model-item label="Country"  prop="country" :rules="req(`Country is required`)">
                            <a-input type="text" v-model="rep.country" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>

            <p class="text-danger" v-if="participantModal.errorMsg">{{participantModal.errorMsg}}</p>
        </a-modal>
		<a-modal :visible="formModal.visible" :centered="true" :footer="null" @cancel="closeForm" :width="650">
            <div class="dF aC">
                <h5 style="margin:0" class="mr-3">Form Submission</h5>
            </div>
            <div v-if="formModal.previewForm && Object.keys(formModal.previewForm).length != 0 && formModal.previewForm.data && formModal.previewForm.data.fields && formModal.previewForm.data.fields.length">
                <div v-if="formModal.previewForm.data.fields[0] && formModal.previewForm.data.fields[0].key == 'url'" class="dF aC"><div>{{`Form Url: `}}</div>&nbsp;<div style="color:var(--orange)"><a :href="formModal.previewForm.data.fields[0].value" target="_blank">{{formModal.previewForm.data.fields[0].value}}</a></div></div>
                <a-row class="mt-5" :gutter="[16, 16]" v-if="formModal.previewForm.form && formModal.previewForm.form.fields">
                    <div v-for="(field) in reformat(formModal.previewForm.form.fields)" :key="field.id" :value="field.id">
                        <template v-if="field.type != 'header'">
                            <a-col :span="12">
                                <div style="color:#000">{{field.label}}</div>
                                <a :href="field.value" target="_blank" v-if="field.type == 'file'">{{field.fileName}}</a>
                                <div v-else>{{field.value}}</div>
                            </a-col>
                        </template>
                        <template v-else>
                            <a-col :span="24">
                                <a-collapse>
                                    <a-collapse-panel :key="field.id" :header="field.label">
                                        <a-col :span="12" v-for="child in field.children" :key="child.id" :value="child.id">
                                            <div style="color:#000">{{child.label}}</div>
                                            <a :href="child.value" target="_blank" v-if="child.type == 'file'">{{child.fileName}}</a>
                                            <div v-else-if="child.type == 'checkbox' && typeof child.value == 'object' && child.value.length">
                                                <div v-for="(ans,ansI) in child.value" :value="ans+ansI" :key="ans+ansI">{{ans}}</div>
                                            </div>
                                            <div v-else>{{child.value}}</div>
                                        </a-col>
                                    </a-collapse-panel>
                                </a-collapse>
                            </a-col>
                        </template>
                    </div>
                </a-row>
            </div>
        </a-modal>
		<a-modal :centered="true" :footer="null" :visible="previewModal.visible" @cancel="closePreview" :width="'auto'">
            <template v-if="previewModal.visible && Object.keys(previewModal.file).length != 0">
                <img style="margin:10px; width:auto; max-width:600px; max-height:600px; object-fit:cover" v-if="previewModal.file.label.includes('png') || previewModal.file.label.includes('jpeg') || previewModal.file.label.includes('jpg') || previewModal.file.label.includes('svg') || previewModal.file.label.includes('JPG')" :src="previewModal.file.value" />
            </template>
        </a-modal>
		<a-modal :visible="contactModal.show" :title="`${whoName} Details`" class="modal-large" @cancel="closeContact" @ok="closeContact" :footer="null" centered>
			<div class="w-full dF">
				<a-menu v-model="contactModal.currentTab" style="width: 256px" class="mr-3 custom-menu-style" mode="vertical" size="large">
					<a-menu-item key="1" class="dF" style="font-weight: bold; height: 60px; line-height: 60px; align-items: center; border-bottom:1px solid #E4E7EB;">
						<svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px;" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
						Form Submissions ({{ contactModal.forms.length || 0 }})
					</a-menu-item>
					<a-menu-item key="2" class="dF" style="font-weight: bold; height: 60px; line-height: 60px; align-items: center; border-bottom:1px solid #E4E7EB;">
						<a-icon style="font-size: 25px;" type="file" />
						Attachments ({{ contactModal.attachments.length || 0 }})
					</a-menu-item>
				</a-menu>
				<div class="f1">
					<div v-if="contactModal.currentTab == '1'">
						<template v-if="contactModal.forms && contactModal.forms.length">
							<div :class="formI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(form, formI) in contactModal.forms" :key="form.id" :value="form.id">
								<div class="dF">
									<div class="smallIcon mr-3" style="background-color: #FFDEAD;">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
									</div>
									<div>
										<div style="color:black; font-size:15px">{{form.form && form.form.name ? form.form.name : 'N/A'}}</div>
										<div style="color:#9EA0A5">{{getDateString(form.createdAt)}}</div>
										<div style="color:var(--orange)" v-if="form.contact">{{form.contact.firstName && form.contact.lastName && form.contact.firstName != '' && form.contact.lastName != '' ? `Submitted by ${form.contact.firstName} ${form.contact.lastName}` :''}}</div>
									</div>
								</div>
								<div>
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
										<template slot="title">View Form</template>
										<a-icon @click="openForm(form)" style="font-size:16px; cursor:pointer" type="eye" class="action-icons" />
									</a-tooltip>
								</div>
							</div>
						</template>
						<template v-else>This lead doesn't have any form...</template>
					</div>
					<div v-if="contactModal.currentTab == '2'">
						<template v-if="contactModal.attachments && contactModal.attachments.length">
							<div :class="attachmentI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(attachment, attachmentI) in contactModal.attachments" :key="attachmentI" :value="attachmentI">
								<div class="dF f1">
									<div class="smallIcon mr-3 dF aC jC" style="background-color: #FFDEAD;">
										<i style="font-size:16px; color:var(--orange)" class="fa fa-file" />
									</div>
									<div class="f1">
										<div style="color:var(--orange); font-size:15px">{{attachment.label}}</div>
									</div>
								</div>
								<div>
									<a-tooltip class="mr-2" slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">View Attachment</template>
										<a-icon v-if="attachment.label.includes('png') || attachment.label.includes('jpeg') || attachment.label.includes('jpg') || attachment.label.includes('svg') || attachment.label.includes('JPG')" @click="viewAttachment(attachment)" style="font-size:16px" class="action-button" type="eye" />
										<a v-else :href="attachment.value" target="_blank"><a-icon style="font-size:16px;cursor:pointer" type="eye" /></a>
									</a-tooltip>
									<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
										<template slot="title">Download Attachment</template>
										<a :href="attachment.value" target="_blank"><i style="font-size:16px;cursor:pointer" class="fe fe-download" /></a>
									</a-tooltip>
								</div>
							</div>
						</template>
						<template v-else>This lead doesn't have any attachment...</template>
					</div>
				</div>
			</div>
		</a-modal>

        <a-row :gutter="32">
            <a-col :span="12">

                <h5>{{whoName}}(s)</h5>
                <p v-if="purchaserNotAdded && purchasers.length === 0" class="text-danger">Must add atleast one {{whoName}} to continue</p>
                <a-divider class="mb-0" />


                <!-- LIST OF ADDED PURCHASERS -->
                <ParticipantCard v-for="buyer in purchasersSummarized" :key="buyer.id" :item="buyer" @view="viewPurchaser" @edit="editPurchaser" @trash="(e) => editPurchaser(e,true)" :detailsTab="true" :contactDetails="true" @contact="viewContact" />




                <!-- ADD NEW PURCHASER BUTTON -->

                <a-dropdown v-if="editingRights && !['completed', 'void', 'created'].includes(transaction.status) && maxPurchasers > purchasers.length" :trigger="['click']" style="width:auto">
                    <div class="dF py-4 aC w-full purchaser-side-add-card cursor-pointer hover:text-primary no-selection-parent">
                        <div class="add-circle-dashed">
                            <i class="fe fe-plus" />
                        </div>
                        <div class="f1 pl-4">
                            Add {{purchaserLabel[purchasers.length] || `${whoName} ${purchasers.length + 1}`}}
                        </div>
                    </div>

                    <a-menu slot="overlay" @click="addPurchaserModal">
                        <a-menu-item key="scan">
                                <i class="fe fe-camera" style="width:17px;display:inline-block" /> Scan Driver's License
                        </a-menu-item>
                        <a-menu-item key="manual">
                            <i class="fe fe-plus" style="width:17px;display:inline-block" /> Create Manually
                        </a-menu-item>

                    </a-menu>

                </a-dropdown>

            </a-col>
            <a-col :span="12">
                <h5>{{whoName}}'s Reps</h5>
                <a-divider class="mb-0" />


                <ParticipantCard :item="repsSummarized.solicitor" @view="viewRep" @edit="addEditRep" @trash="(e) => deleteRep(e,true)" v-if="repsSummarized.solicitor" :detailsTab="true" />
                <template v-else>
                    <div class="dF py-4 aC w-full purchaser-side-add-card cursor-pointer hover:text-primary no-selection-parent" @click="addEditRep('solicitor')" v-if="editingRights && !['completed', 'void', 'created'].includes(transaction.status)" >
                        <div class="add-circle-dashed">
                            <i class="fe fe-plus" />
                        </div>
                        <div class="f1 pl-4">
                            Add {{whoName}}'s Lawyer
                        </div>
                    </div>
                </template>


                <ParticipantCard :item="repsSummarized.agent" @view="viewRep" @edit="addEditRep" @trash="(e) => deleteRep(e,true)" v-if="repsSummarized.agent" :detailsTab="true" />
                <template v-else>
                    <div class="dF py-4 aC w-full purchaser-side-add-card cursor-pointer hover:text-primary no-selection-parent" @click="addEditRep('agent')" v-if="editingRights && !['completed', 'void', 'created'].includes(transaction.status)">
                        <div class="add-circle-dashed">
                            <i class="fe fe-plus" />
                        </div>
                        <div class="f1 pl-4">
                            Add {{whoName}}'s Agent
                        </div>
                    </div>
                </template>


            </a-col>
        </a-row>

		<a-modal v-model="viewParticipantData" :title="`${whoName}'s Details`" :footer="null" @close="resetData" :width="800" centered>
			<a-descriptions layout="horizontal">
				<a-descriptions-item label="First Name">
					{{purchaser.firstName}}
				</a-descriptions-item>
				<a-descriptions-item label="Last Name">
					{{purchaser.lastName}}
				</a-descriptions-item>
				<a-descriptions-item label="Middle Name">
					{{purchaser.middleName}}
				</a-descriptions-item>
				<a-descriptions-item label="Email">
					{{purchaser.email}}
				</a-descriptions-item>
				<a-descriptions-item label="Phone Number">
					{{purchaser.phone}}
				</a-descriptions-item>
				<a-descriptions-item label="Date of Birth">
					{{purchaser.dob}}
				</a-descriptions-item>
				<a-descriptions-item label="Business Phone#">
					{{purchaser.business}}
				</a-descriptions-item>
				<a-descriptions-item label="Cell Phone#">
					{{purchaser.cell}}
				</a-descriptions-item>
				<a-descriptions-item label="Company">
					{{purchaser.company}}
				</a-descriptions-item>
				<a-descriptions-item label="Occupation">
					{{purchaser.occupation}}
				</a-descriptions-item>
				<a-descriptions-item label="Job Title">
					{{purchaser.jobTitle}}
				</a-descriptions-item>
				<a-descriptions-item label="SSN/SIN #">
					{{purchaser.ssnsin}}
				</a-descriptions-item>
				<a-descriptions-item label="Driver License #">
					{{purchaser.idValue}}
				</a-descriptions-item>
				<a-descriptions-item label="License Issuing Jurisdiction">
					{{purchaser.issuing}}
				</a-descriptions-item>
				<a-descriptions-item label="License Issuing Jurisdiction - Country">
					{{purchaser.issuingCountry}}
				</a-descriptions-item>
				<a-descriptions-item label="License Expiry">
					{{purchaser.expiry}}
				</a-descriptions-item>
				<a-descriptions-item :label="purchaser.idType === 'pp'? 'Passport #' : 'Other #'">
					{{purchaser.passportOtherValue}}
				</a-descriptions-item>
				<a-descriptions-item :label="purchaser.idType === 'pp'? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'">
					{{purchaser.passportOtherIssuing}}
				</a-descriptions-item>
				<a-descriptions-item :label="purchaser.idType === 'pp'? 'Passport Issuing Jurisdiction - Country' : 'Other Issuing Jurisdiction - Country'">
					{{purchaser.passportOtherIssuingCountry}}
				</a-descriptions-item>
				<a-descriptions-item :label="purchaser.idType === 'pp'? 'Passport Expiry' : 'Other Expiry'">
					{{purchaser.passportOtherExpiry}}
				</a-descriptions-item>
				<a-descriptions-item label="Address 1" :span="2">
					{{purchaser.address1}}
				</a-descriptions-item>
				<a-descriptions-item label="Address 2">
					{{purchaser.address2}}
				</a-descriptions-item>
				<a-descriptions-item label="City">
					{{purchaser.city}}
				</a-descriptions-item>
				<a-descriptions-item label="State/Province">
					{{purchaser.region}}
				</a-descriptions-item>
				<a-descriptions-item label="Zip/Postal Code">
					{{purchaser.postal}}
				</a-descriptions-item>
				<a-descriptions-item label="Country">
					{{purchaser.country}}
				</a-descriptions-item>
			</a-descriptions>

			<div v-if="purchaser.fileFront" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>License Front Photo</h5>
				<img :src="purchaserFrontFileSignedUrl" alt="License Front Photo" style="max-width: 550px; max-height: 550px;" />
			</div>
			<div v-if="purchaser.fileBack" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>License Back Photo</h5>
				<img :src="purchaserBackFileSignedUrl" alt="License Back Photo" style="max-width: 550px; max-height: 550px;" />
			</div>
			<div v-if="purchaser.filePassport" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>Passport Photo</h5>
				<img :src="purchaserPassportFileSignedUrl" alt="Passport Photo" style="max-width: 550px; max-height: 550px;" />
			</div>
		</a-modal>

		<a-modal v-model="viewRepData" :title="`${rep.id === 'agent' ? `${whoName}\'s Agent` : `${whoName}\'s Lawyer`} Details`" :footer="null" @close="resetData" :width="800">
			<a-descriptions layout="horizontal">
				<a-descriptions-item label="First Name">
					{{rep.firstName}}
				</a-descriptions-item>
				<a-descriptions-item label="Last Name">
					{{rep.lastName}}
				</a-descriptions-item>
				<a-descriptions-item label="Email">
					{{rep.email}}
				</a-descriptions-item>
				<a-descriptions-item label="Phone Number">
					{{rep.phone}}
				</a-descriptions-item>
				<a-descriptions-item label="Cell Number">
					{{rep.cell}}
				</a-descriptions-item>
				<a-descriptions-item label="Company">
					{{rep.company}}
				</a-descriptions-item>
				<a-descriptions-item label="Job Title">
					{{rep.jobTitle}}
				</a-descriptions-item>
				<a-descriptions-item label="Address 1" :span="2">
					{{rep.address1}}
				</a-descriptions-item>
				<a-descriptions-item label="Address 2">
					{{rep.address2}}
				</a-descriptions-item>
				<a-descriptions-item label="City">
					{{rep.city}}
				</a-descriptions-item>
				<a-descriptions-item label="State/Province">
					{{rep.region}}
				</a-descriptions-item>
				<a-descriptions-item label="Zip/Postal Code">
					{{rep.postal}}
				</a-descriptions-item>
				<a-descriptions-item label="Country">
					{{rep.country}}
				</a-descriptions-item>
			</a-descriptions>
		</a-modal>
    </a-card>
</template>

<script>
    import {validateEmail} from 'bh-mod'
    import ParticipantCard from '@/components/transaction/ParticipantCard'
    import moment from 'moment'
    import ScanLicense from '@/components/common/scanLicense.vue'

    let isEmail =  (rule, value, callback) => {
        if (value === '') {
            callback(new Error('Email is required'))
        } else if (!validateEmail(value)) {
            callback(new Error('Email is not valid'))
        }
        callback()
    }

export default {
    components:{ParticipantCard,ScanLicense},
    data: () => ({
        scanOptions:{
            show:false,
        },
        licenseScan:false,
        purchaserNotAdded:false,
		viewParticipantData: false,
		viewRepData: false,
        isEmail,
        participantModal:{
            show:false,
            edit:false,
            type:'purchaser',
            title:'',
            id:'',
            errorMsg:'asdf',
        },
        rep:{
            amended:true,
            firstName:'',
            lastName:'',
            email:'',
            phone:'',
			cell:'',
            company:'',
            jobTitle:'',
            address1:'',
            address2:'',
            postal:'',
            country:'',
            region:'',
            city:'',
        },
        purchaser:{
            amended:true,
            id:'',
            firstName:'',
            middleName:'',
            lastName:'',
            email:'',
            phone:'',
            dob:'',
            cell:'',
            issuing:'',
			issuingCountry:'',
            expiry:'',
            business:'',
			occupation:'',
            company:'',
            idType:'pp',
            idValue:'',
			passportOtherValue: '',
			passportOtherIssuing: '',
			passportOtherIssuingCountry: '',
			passportOtherExpiry: '',
            address1:'',
            address2:'',
            postal:'',
            country:'',
            region:'',
            city:'',
            jobTitle:'',
			ssnsin: ''
        },
		refreshKey: 0,
		purchaserFrontFileSignedUrl: "",
		purchaserBackFileSignedUrl: "",
		purchaserPassportFileSignedUrl: "",
		maxPurchasers: 1,
		contactModal:{
			show:false,
			currentTab:'1',
			forms:[],
			attachments:[]
		},
		formModal:{
			visible:false,
			previewForm:{}
		},
		previewModal:{
			visible:false,
			file:{}
		}
    }),
    computed:{
		purchaserLabel(){
            return this.transaction.type === 'lease' ? ['Primary Tenant', 'Secondary Tenant'] : ['Primary Purchaser', 'Secondary Purchaser']
        },
		whoName(){
            return this.transaction.type === 'lease' ? 'Tenant' : 'Purchaser'
        },
        purchasers(){
            return this.transaction.purchasers || []
        },
        reps(){
            let { agent = {}, solicitor = {} } = this.transaction.reps
            if (!agent.amended) agent.locked = true
            if (!solicitor.amended) solicitor.locked = true
            return {agent,solicitor}
        },
        signers(){
            return this.$store.getters.currentParticipants.signingEmails
        },
        editingRights(){
            let canEdit = false
            if (this.$p >= 40){
                return true
            } else {
                this.transaction.owners.forEach( x => {
                    if (x.id == this.user.id){
                        canEdit = true
                    }
                })
                return canEdit
            }
        },
        user(){
            return this.$store.state.user.user
        },
        transaction(){
            return this.$store.state.appData.currentTransaction
        },
        repsSummarized() {
			let obj = {
				agent: false,
				solicitor: false,
			}
			if (this.reps && this.reps.solicitor && this.reps.solicitor.email) {
				let { firstName, lastName, id, email } = this.reps.solicitor
				obj.solicitor = { locked: true, name: `${firstName} ${lastName}`, description: `${this.whoName}'s Lawyer (${email})`, id }
			}
			if (this.reps && this.reps.agent && this.reps.agent.email) {
				let { firstName, lastName, id, email, phone, cell } = this.reps.agent
				obj.agent = { locked: true, name: `${firstName} ${lastName}`, description: `${this.whoName}'s Agent (Email: ${email}` + `${phone && phone.length ? `, Phone: ${phone}` : ''}` + `${cell && cell.length ? `, Cell: ${cell}` : ''}` + ')', id }
			}
			return obj
		},
        purchasersSummarized(){
			// Do not remove this line as it's being use for deep field change refresh
			let key = this.refreshKey;
            return this.purchasers.map( (x,xI) => {
                return {
                    locked: true,
                    name:`${x.firstName} ${x.lastName}`,
					email:x.email.toLowerCase(),
                    description:(this.purchaserLabel[xI] || `${this.whoName} ${xI + 1}`) + ` (Email: ${x.email.toLowerCase()}` + `${x.phone && x.phone.length ? `, Phone: ${x.phone}` : ''}` + ')',
                    id:x.id
                }
            })
        },

		currentContract(){
            return this.transaction.template && this.$store.state.appData.templates[this.transaction.template] || {}
        },

		identificationTypes() {
			return this.$store.state.appData.identificationTypes
		},

		passportOther() {
			return this.$store.state.appData.passportOther
		},
    },
	watch: {
		'purchaser.idType'(){
			this.$refs.purchaser && this.$refs.purchaser.clearValidate('passportOtherValue')
		},
		'purchaser.idValue'(){
			this.$refs.purchaser && this.$refs.purchaser.clearValidate('passportOtherValue')
		},
		'purchaser.passportOtherValue'(){
			this.$refs.purchaser && this.$refs.purchaser.clearValidate('idValue')
		},
	},
    methods:{
		reformat(array) {
            let data = this.formModal.previewForm.data && this.formModal.previewForm.data.fields || []
            let fields = []
            let isHeader = false
            let headerObj = {
                id:'',
                value:'',
                label:'',
                type:'',
                children:[]
            }
            array.forEach(field => {
                let obj = {
                    id:'',
                    value:'',
                    label:'',
                    type:'',
                }
                if (field.type != 'header') {
                    if (!field.hide) {
                        if (field.type == 'name') {
                            let first = {}
                            first.id = 'firstName'
                            first.type = 'name'
                            let firstName = data.find (x => x.key && x.key == 'firstName')
                            if (firstName) {
                                first.label = 'First Name'
                                first.value = firstName.value
                            }
                            let last = {}
                            last.id = 'lastName'
                            last.type = 'name'
                            let lastName = data.find (x => x.key && x.key == 'lastName')
                            if (lastName) {
                                last.label = 'Last Name'
                                last.value = lastName.value
                            }
                            if (!isHeader){
                                fields.push(first)
                                fields.push(last)
                            } else {
                                headerObj.children.push(first)
                                headerObj.children.push(last)
                            }

                            let properties = ['middleInitial','prefix','suffix']
                            properties.forEach(key => {
                                if (field[key]) {
                                    let object = {}
                                    object.id = key
                                    object.type = 'name'
                                    let find = data.find(x => x.key && x.key == key)
                                    if (find) {
                                        object.label = find.label
                                        object.value = find.value
                                    }
                                    if (!isHeader){
                                        fields.push(object)
                                    } else {
                                        headerObj.children.push(object)
                                    }
                                }
                            })
                        } else if (field.type == 'phone') {
                            obj.id = 'phone'
                            obj.type = 'phone'
                            let find = data.find(x => x.key && x.key == 'phone')
                            if (find) {
                                obj.label = 'Phone'
                                obj.value = find.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'email') {
                            obj.id = 'email'
                            obj.type = 'email'
                            let find = data.find(x => x.key && x.key == 'email')
                            if (find) {
                                obj.label = 'Email'
                                obj.value = find.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'address') {
                            if (field.options.addressOne) {
                                let add1 = {}
                                add1.id = 'address'
                                add1.type = 'address'
                                let find = data.find(x => x.key && x.key == 'address')
                                if (find) {
                                    add1.value = find.value
                                    add1.label = find.label
                                }
                                if (!isHeader){
                                    fields.push(add1)
                                } else {
                                    headerObj.children.push(add1)
                                }
                            }
                            if (field.options.addressTwo) {
                                let add2 = {}
                                add2.id = 'address2'
                                add2.type = 'address'
                                let find = data.find(x => x.key && x.key == 'address2')
                                if (find) {
                                    add2.value = find.value
                                    add2.label = find.label
                                }
                                if (!isHeader){
                                    fields.push(add2)
                                } else {
                                    headerObj.children.push(add2)
                                }
                            }
                            let properties = ['city', 'country', 'postal', 'province']
                            properties.forEach(key => {
                                if (field.options[key]) {
                                    let object = {}
                                    object.id = key
                                    object.type = 'address'
                                    if (key != 'province') {
                                        let find = data.find(x => x.key && x.key == key)
                                        console.log('finddddd',find)
                                        if (find) {
                                            object.label = find.label
                                            object.value = find.value
                                        }
                                    } else {
                                        let find = data.find(x => x.key && x.key == 'region')
                                        if (find) {
                                            object.label = find.label
                                            object.value = find.value
                                        }
                                    }
                                    if (!isHeader){
                                        fields.push(object)
                                    } else {
                                        headerObj.children.push(object)
                                    }
                                }
                            })
                        } else if (field.type == 'isAgent') {
                            obj.id = 'isAgent'
                            obj.type = 'isAgent'
                            obj.label = 'Is Agent?'
							let agentValue = data.find(x => x.key && x.key == 'isAgent')
							console.log('IS AGENT FIELD VALUE', agentValue)
                            if (agentValue && (agentValue.value == '1' || agentValue.value == 'true' || agentValue.value == true)){
                                obj.value = 'Yes'
                            } else {
                                obj.value = 'No'
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'file') {
                            obj.id = field.id.toString()
                            obj.type = field.type
                            obj.label = field.label
                            let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
                            if (find) {
                                obj.fileName = find.label
                                obj.value = find.value
								if (!obj.value.includes('https')) obj.value = 'https://' + obj.value
                            }
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        } else if (field.type == 'checkbox') {
							if (field.multiple){
								obj.id = field.id.toString()
								obj.type = field.type
								obj.label = field.label
								let findAll = data.filter(x => x.field && x.field.id && x.field.id == field.id)
								let listValue = []
								findAll.forEach(x => {
									if (!x.value.includes('other')){
										let split = x.value.split(',')
										listValue = listValue.concat(split)
									} else {
										let newValue = ''
										if (x.value.includes(',other')){
											newValue = x.value.replace(',other','')
										} else {
											newValue = x.value.replace('other','')
										}
										let split = newValue.split(',')
										listValue = listValue.concat(split)
									}
								})
								listValue = listValue.filter(x => x != '').join(', ')
								obj.value = listValue

								if (!isHeader){
									fields.push(obj)
								} else {
									headerObj.children.push(obj)
								}
							} else {
								obj.id = field.id.toString()
								obj.type = field.type
								obj.label = field.label
								let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
								if (find && find.value == 'on') {
									obj.value = 'True'
								} else if (find) {
									obj.value = 'False'
								}
								if (!isHeader){
									fields.push(obj)
								} else {
									headerObj.children.push(obj)
								}
							}
                        } else {
							console.log('FIELD CHECK', field)
                            obj.id = field.id.toString()
                            obj.type = field.type
                            let find = data.find(x => x.field && x.field.id && x.field.id == field.id)
                            if (find) {
                                obj.label = find.label
                                obj.value = find.value
                            }
							console.log('OBJECT VALUE', obj.value)
							if (obj.value == 'other'){
								let findOther = data.find(x => x.field && x.field.id && x.field.id == field.id && x.value != 'other')
								console.log('FIND OTHER', findOther)
								obj.value = findOther.value
							}
                            if (!isHeader){
                                fields.push(obj)
                            } else {
                                headerObj.children.push(obj)
                            }
                        }
                    }
                } else {
                    if (!isHeader) {
                        isHeader = true
                        headerObj = JSON.parse(JSON.stringify(field))
                        headerObj.children = []
                    } else {
                        let newObj = JSON.parse(JSON.stringify(headerObj))
                        fields.push(newObj)
                        headerObj = JSON.parse(JSON.stringify(field))
                        headerObj.children = []
                    }
                }
            })
            if (isHeader){
                fields.push(headerObj)
            }
            console.log('fieldssss', fields)
            return fields
        },
		async viewContact(e){
			console.log('VIEWING CONTACT', e)
			this.$store.commit('LOAD', true)
			let contact = await this.$api.get(`/contacts/:instance?email=${e.email}`)
			if (contact && contact.data && contact.data.length){
				let id = contact.data[0].id
				if (contact.data[0].others && contact.data[0].others.attachments) {
					this.contactModal.attachments = this.contactModal.attachments.concat(contact.data[0].others.attachments)
				}
				this.$api.get(`/form-submits/:instance?contact=${id}`).then(({data}) => {
					console.log('DATA COMING BACK FROM FORM SUBMIT', data)
					this.contactModal.forms = data
					this.contactModal.forms.forEach(form => {
						if (form.data && form.data.fields) {
							let files = form.data.fields.filter(x => {
								if (x.field && x.field.type && x.field.type == 'file' && x.value) return x
							})
							files.map(x => {
								if (!x.value.includes('https')){
									console.log('DOESN"T INCLUDE')
									x.value = 'https://' + x.value
									return x
								}
								return x
							})
							this.contactModal.attachments = this.contactModal.attachments.concat(files)
						}
					})
					this.contactModal.show = true
					console.log('CONTACT MODAL', this.contactModal)
					this.$store.commit('LOAD', false)
				}).catch( e => {
					console.log('ERROR GETTING FORM SUBMIT', e)
					this.$store.commit('LOAD', false)
				})
			} else {
				this.$message.error('No contact found!')
				this.$store.commit('LOAD', false)
			}
		},
		closeContact(){
			this.contactModal = {
				show: false,
				currentTab: '1',
				forms: [],
				attachments: []
			}
		},
		viewAttachment(file) {
            if (file.label.includes('png') || file.label.includes('jpg') || file.label.includes('jpeg') || file.label.includes('svg') || file.label.includes('JPG')) {
                this.previewModal.visible = true
                this.previewModal.file = file
            }
        },
		closeForm() {
            this.formModal.visible = false
            this.formModal.previewForm = {}
        },
		openForm(form) {
            console.log('forms',form)
            this.formModal.previewForm = form
            this.formModal.visible = true
        },
		closePreview(){
			this.previewModal.visible = false
            this.previewModal.file = {}
		},
		getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
        closeScan(){
            this.scanOptions = {
                show:false,
            }
        },
        todayDate(){
            let numDate = new Date(Date.now())
            let month = (numDate.getMonth()+1).toString()
            let day = (numDate.getDate()).toString()
            let year = numDate.getFullYear()
            if (month.length < 2) {
                month = '0' + month
            }
            if (day.length < 2){
                day = '0' + day
            }
            // let time = numDate.getTime()
            // let formatTime = this.moment(time).format('HH:mm')
            let finalCreatedDate = year + '-' + month + '-' + day
            return finalCreatedDate
        },
        disabledDate(current) {
            return current && current > moment().startOf('day')
        },
        deleteRep(type){

            let role = type === 'agent' ? 'Agent' : 'Lawyer'
            let obj = this.reps[type]
            let self = this
            this.$confirm({
                title: `Are you sure you want to delete ${this.whoName}'s ${role}:  ${obj.firstName} ${obj.lastName} ?`,
                okText:'Delete',
                okType: 'danger',
                centered:true,
                onOk() {
                    self.$store.commit('SET_PROP', {
                        where:['currentTransaction','reps',type],
                        what:{
                            amended:true,
                            firstName:'',
                            lastName:'',
                            email:'',
                            phone:'',
                            company:'',
                            jobTitle:'',
                            address1:'',
                            address2:'',
                            postal:'',
                            country:'',
                            region:'',
                            city:'',
                            id:'',
                        }
                    })
                },
                onCancel() {
                    console.log('Canceled delete of purchaser');
                },
            })
        },
        addEditRep(type){
			this.resetData();
            let role = type === 'agent' ? 'Agent' : 'Lawyer'
            if (this.reps[type].id) {

                this.rep = JSON.parse(JSON.stringify(this.reps[type]))
                this.participantModal = {
                    show:true,
                    edit:true,
                    title:`${this.whoName}'s ${role}` ,
                    type,
                    id:'',
                    errorMsg:'',
                }

            } else {

                this.participantModal = {
                    show:true,
                    edit:false,
                    title:`${this.whoName}'s ${role}` ,
                    type,
                    id:'',
                    errorMsg:'',
                }
            }



        },
        editPurchaser(id = '', del = false ){
            if (!id) return null

            let idx = this.purchasers.findIndex(x => x.id === id)
            if (idx === -1) return null

            let self = this

            let purchaser = this.purchasers[idx]
            let pLabel = this.purchaserLabel[idx] || `${this.whoName} ${idx + 1}`

            if (del) {
                return this.$confirm({
                    title: `Are you sure you want to delete ${this.whoName} #${idx + 1}:  ${purchaser.firstName} ${purchaser.lastName} ?`,
                    okText:'Delete',
                    okType: 'danger',
                    centered:true,
                    onOk() {
                        self.purchasers.splice(idx,1)
                    },
                    onCancel() {
                        console.log('Canceled delete of purchaser');
                    },
                })

            }

            this.participantModal = {
                show:true,
                edit:true,
                title:'Edit ' + pLabel,
                type:'purchaser',
                id,
                errorMsg:'',
            }
            this.purchaser = {...purchaser, idType: purchaser.idType === 'dl' ? 'pp' : purchaser.idType }


        },
        req:msg=>({required:true,message:msg}),
        resetModal() {
			if (this.$refs.purchaser) this.$refs.purchaser.resetFields()
			if (this.$refs.rep) this.$refs.rep.resetFields()

			this.participantModal = {
				show: false,
				edit: false,
				type: 'purchaser',
				title: '',
				id: '',
				errorMsg: '',
			}
			this.viewParticipantData = false;
			this.viewRepData = false;
		},
        createParticipant() {
			let self = this
			if (this.participantModal.type === 'purchaser') {
				this.$refs.purchaser.validate((valid) => {
					if (valid) {
						let newP = { ...self.purchaser }
						let firstName = newP.firstName
						let lastName = newP.lastName
						if (newP.middleName.trim()) lastName = newP.middleName.trim() + ' ' + lastName

						newP.fullName = `${firstName} ${lastName}`
						newP.address = `${newP.address1}${newP.address2 ? ', ' + newP.address2 : ''}`;
						newP.cityRegionPostalZip = `${newP.city}, ${newP.region}, ${newP.postal}`;
						newP.fullAddress = `${newP.address1}, ${newP.address2}, ${newP.city}, ${newP.region}, ${newP.postal}`.replaceAll(', , ', ', ').trim();

						if (newP.idValue && newP.idValue != ''){
							newP.licenseIdType = "Driver's License"
						} else {
							newP.licenseIdType = ""
						}

						if (newP.passportOtherValue){
							newP.passportOtherIdType = this.passportOther.idType[newP.idType] || 'Other';
						} else {
							newP.passportOtherIdType = ""
						}

						let found = self.purchasers.findIndex(x => x.id === newP.id)
						let index = self.purchasers.length

						if (found > -1) {
							index = found
							if (!self.participantModal.edit) return self.participantModal.errorMsg = 'You already added this purchaser'
						} else {
							newP.id = Date.now();
						}

						self.$store.commit('SET_PROP', { where: ['currentTransaction', 'purchasers', index], what: JSON.parse(JSON.stringify(newP)) })
						self.participantModal.errorMsg = false;
						self.refreshKey++;

						if (self.participantModal.edit) {
							self.updateParticipant(newP, index)
						} else {
							self.$api.put(`/transactions/:instance/${self.transaction.id}`, { purchasers: self.purchasers }).catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
	                        })
						}
						self.resetModal()
					} else {
						return false;
					}
				})

			} else {
				this.$refs.rep.validate((valid) => {
					if (valid && self.reps[self.participantModal.type]) {
						let newP = { ...self.rep }
						newP.fullName = `${newP.firstName} ${newP.lastName}`
						newP.id = self.participantModal.type
						newP.address = `${newP.address1}${newP.address2 ? ', ' + newP.address2 : ''}`;
						newP.cityRegionPostalZip = `${newP.city}, ${newP.region}, ${newP.postal}`;
						newP.fullAddress = `${newP.address1}, ${newP.address2}, ${newP.city}, ${newP.region}, ${newP.postal}`.replaceAll(', , ', ', ').trim();

						self.$store.commit('SET_PROP', { where: ['currentTransaction', 'reps', self.participantModal.type], what: JSON.parse(JSON.stringify(newP)) })
						self.participantModal.errorMsg = false
						self.refreshKey++;

						if (self.participantModal.edit) {
							self.updateParticipant(newP)
						} else {
							self.$api.put(`/transactions/:instance/${self.transaction.id}`, { reps: self.reps }).catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
	                        })
						}
						self.resetModal()
					} else {
						return false;
					}
				})
			}
		},

		async updateParticipant(participant, index) {
			if (this.isMainEnvelopSigned()) {
				return this.$message.error('The document is already signed by at least one person. You can not update the purchaser details.')
			}
			this.$store.commit('LOAD', true)
			// updating index to check relevant fields only from contract template
			let purchaserFieldIndex = index + 1;

			const signer = this.generateSingerObj(participant, purchaserFieldIndex)

			try {
				const payload = {
					...participant,
					signer
				}
				const type = this.participantModal.type === 'purchaser' ? 'purchasers' : 'reps'
				await this.$api.put(`transactions/:instance/${this.transaction.id}/${type}/${participant.id}`, payload)
				this.$store.commit('LOAD', false)
			} catch (err) {
				this.$store.commit('LOAD', false)
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while updating purchaser details. Please try again.'))
				}
			}
		},

		generateSingerObj(participant, purchaserIndex) {
			let contract = this.currentContract;
			let { pages = [] } = contract

			let signingObj = {
				solicitor: false,
				agent: false,
				signingParty: false,
				purchasers: false,
				purchaserSide: true,
				action: 'cc',
				actionDisabled: false,
				disabled: false,
				other: true,
				predefined: true,
				id: Date.now(),
				name: '',
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
			}

			const transactionType = this.whoName;

			pages.forEach((page, pageI) => {
				let { fields = [] } = page
				fields.forEach(spot => {
					let { field, text } = spot
					let fV = field.value
					let fT = field.type
					let canSign = false

					if (fT === 'initial' || fT === 'sign') canSign = true
					if (signingObj.canSign) canSign = true

					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (fV.includes('purchasers[]') && text.purchasers === purchaserIndex) {
							signingObj = {
								...signingObj,
								canSign,
								actionDisabled: true,
								disabled: true,
								order: 1,
								id: `${transactionType}${text.purchasers},`,
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${transactionType} #${text.purchasers}`,
								supportingText: `${transactionType} #${text.purchasers}`,
								email: participant.email,
								purchasers: text.purchasers,
								signLocations: {}
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						} else if (fV.includes('reps.agent') && participant.id === 'agent') {
							signingObj = {
								...signingObj,
								canSign,
								agent: true,
								actionDisabled: false,
								disabled: true,
								order: 1,
								id: 'agent',
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${transactionType}'s Agent`,
								supportingText: `${transactionType}'s Agent`,
								email: participant.email,
								signLocations: {}
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						} else if (fV.includes('reps.solicitor') && participant.id === 'solicitor') {
							signingObj = {
								...signingObj,
								canSign,
								solicitor: true,
								actionDisabled: false,
								disabled: true,
								order: 1,
								id: 'solicitor',
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${transactionType}'s Lawyer`,
								supportingText: `${transactionType}'s Lawyer`,
								email: participant.email,
								signLocations: {},
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						}
					}
				})
			})

			return signingObj;
		},

        addPurchaserModal(e){
			this.resetData();

            let pLabel = this.purchaserLabel[this.purchasers.length] || `${this.whoName} ${this.purchasers.length + 1}`

            if (e === 'editScan'){
                this.scanOptions = {
                    show:true,
                    callback:purchaser => {

                        this.purchaser = {
                            ...purchaser,
                            amended:true,
                            email:this.purchaser.email || '',
                            phone:this.purchaser.phone || '',
                        }

                    },
                }
            } else if (e.key === 'manual'){

                this.participantModal = {
                    show:true,
                    edit:false,
                    title:'Add ' + pLabel,
                    type:'purchaser',
                    id:'',
                    errorMsg:'',
                }

                this.purchaser.idType = 'pp'
                this.purchaser.country = 'Canada'
                this.purchaser.region = 'Ontario'
            } else {

                this.scanOptions = {
                    show:true,
                    callback:purchaser => {

                        this.purchaser = {
                            ...purchaser,
                            amended:true,
                        }

                        this.participantModal = {
                            show:true,
                            edit:false,
                            title:'Add ' + pLabel,
                            type:'purchaser',
                            id:'',
                            errorMsg:'',
                        }

                    },
                }

            }
        },
        validate(){
            if (!this.purchasers.length) return this.purchaserNotAdded = true
            this.purchaserNotAdded = false
            this.$emit('done', {
                purchasers:this.purchasers,
                reps:this.reps

            })

        },

		isMainEnvelopSigned() {
			if (!this.transaction.envelopes) {
				return false
			}
			let envelope = this.transaction.envelopes.filter(x => x.main)
			let isSigned = false;

			if (envelope && envelope.length) {
				envelope = envelope[0];
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					return
				}

				let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
				signers = recipients && recipients.signers || [];

				if (signers && signers.length) {
					signers.forEach(s => {
						if (s.status === 'completed') {
							isSigned = true
						}
					})
				}
			}

			return isSigned;
		},

		viewPurchaser(id){
			this.purchaser = this.purchasers.find(p => p.id === id)
			this.fetchPurchaserDocs({...this.purchaser})
			this.viewParticipantData = true;
		},

		viewRep(type){
			this.rep = JSON.parse(JSON.stringify(this.reps[type]))
			this.viewRepData = true;
		},

		resetData() {
			this.viewParticipantData = false;
			this.viewRepData = false;

			this.rep = {
				amended: true,
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				cell: '',
				company: '',
				jobTitle: '',
				address1: '',
				address2: '',
				postal: '',
				country: '',
				region: '',
				city: '',
			}
			this.purchaser = {
				amended: true,
				id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				email: '',
				phone: '',
				dob: '',
				cell: '',
				issuing: '',
				issuingCountry: '',
				expiry: '',
				business: '',
				occupation: '',
				company: '',
				idType: 'pp',
				idValue: '',
				passportOtherValue: '',
				passportOtherIssuing: '',
				passportOtherIssuingCountry: '',
				passportOtherExpiry: '',
				address1: '',
				address2: '',
				postal: '',
				country: '',
				region: '',
				city: '',
				jobTitle: '',
				ssnsin: ''
			}
		},

		async fetchPurchaserDocs(purchaser) {
			this.$store.commit('LOAD', true);
			if (purchaser.fileFront && purchaser.fileFront.id && purchaser.fileBack && purchaser.fileBack.id) {
				let result = await Promise.all([this.fetchFileSignedUrl(purchaser.fileFront.id), this.fetchFileSignedUrl(purchaser.fileBack.id)]);
				if (result[0]) {
					this.purchaserFrontFileSignedUrl = result[0]
				}
				if (result[1]) {
					this.purchaserBackFileSignedUrl = result[1]
				}
			}
			if (purchaser.filePassport && purchaser.filePassport.id) {
				const url = await this.fetchFileSignedUrl(purchaser.filePassport.id)
				if (url) {
					this.purchaserPassportFileSignedUrl = url
				}
			}
			this.$store.commit('LOAD', false);
		},

		async fetchFileSignedUrl(id) {
			try {
				const { data } = await this.$api.post(`upload/files/get-signed-url/${id}`)
				return data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching image url. Please try again.`))
				}
				return null
			}
		}
    },
    mounted(){
        this.$emit('setFn',this.validate)

		let { pages = [] } = this.currentContract

		pages.forEach(page => {
			let { fields = [] } = page
			fields.forEach(spot => {
				let { field, text } = spot
				if (field.value.includes('purchasers[]') && text.purchasers > this.maxPurchasers) {
					this.maxPurchasers = text.purchasers
				}
			})
		})
    }
}
</script>

<style lang="scss">
    .add-circle-dashed,.participant-icon{
        width:40px;
        height:40px;
        border-radius:50%;
        border:1px dashed currentColor;
        display:flex;
        place-items: center;
        place-content: center;
        font-size:20px;
        color:currentColor;
    }
    .participant-icon{
       border:1px solid currentColor;
    }
    .purchaser-side-add-card{
        border-bottom:1px solid var(--light-purple);
        color:var(--med-gray);
        &:hover{
            color:var(--primary);
        }
    }

	.custom-menu-style .ant-menu-item-selected{
        margin:0 !important;
        background-color:#FAF9F9 !important;
        color:#000;
    }
    .custom-menu-style .ant-menu-item-selected svg{
        color:var(--orange);
    }
    .custom-menu-style .ant-menu-item {
        margin:0 !important;
    }

	.smallIcon {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		text-align: center;
		padding: 7px 0;
	}
	.action-button{
		cursor:pointer;
	}
	.action-button:hover{
		color:var(--orange);
	}
</style>
